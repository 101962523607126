import "./App.css";
import profile from "./pic.png";
import linkedIn from "./linkedin-logo.svg";
import twitter from "./twitter-logo.svg";
import github from "./github-logo.svg";

function App() {
  return (
    <div className="App">
      <p className="salute"> Hello | Namaste </p>
      <p className="name">My name is Raj Aryan ( राज आर्यन ).</p>
      <img src={profile} alt="profile" className="image-size" />
      <div className="logo">
        <a href="https://www.linkedin.com/in/mrrsr/">
          <img src={linkedIn} alt="LinkedIn" className="logo-size" />
        </a>
        <a href="https://github.com/mr-rsr">
          <img src={github} alt="github" className="logo-size" />
        </a>
        <a href="https://twitter.com/hlw_rsr">
          <img src={twitter} alt="Twitter" className="logo-size" />
        </a>
      </div>
      <div className="details">
        <p>
          I'm a final year student pursuing a Bachelor's degree in Computer
          Science and Engineering at O.P. Jindal University.
        </p>
        <p>
          With over 2 years of experience as Software Developer, I specialize in
          Full Stack Mobile Development with expertise in Flutter, Dart, Java,
          MySql, MongoDB and Node js. My experience have involved developing
          end-to-end solutions, optimizing app performance, and leading team to
          deliver high quality products. Skilled in Database Management and API
          Development, I am committed to driving innovation and exceeding
          project expectations.
        </p>
      </div>
    </div>
  );
}

export default App;
